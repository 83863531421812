import React, { Component } from "react"
import Layout from "../components/layout-partnership";
import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper'


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);


class PartnerUniversities extends Component {

  componentDidMount() {
    var swiper = new Swiper('.partner-swiper', {
      slidesPerView: 6,
      spaceBetween: 40,
      autoplay: true,
      // direction: 'vertical',
      speed: 300,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        992: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 6,
          spaceBetween: 30,
        }
      }
    });

    var swiper = new Swiper('.blogs-swiper', {
      slidesPerView: 2,
      spaceBetween: 5,
      autoplay: true,
      speed: 300,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        }
      }
    });
  }
  render() {
    const { featuredUniversities } = this.props; 
    function  stripHtml(html) {
      // Use a regular expression to remove HTML tags
      return html.replace(/<[^>]*>/g, '');
    }
    return (
      <Layout>
      <section className="partner-sec partner-custom-styling">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3">
              <div className="partner-inner">
                <div className="swiper-container partner-swiper">
                  <div className="swiper-wrapper d-flex align-items-center">
                    {featuredUniversities &&
                      featuredUniversities.featured_partner_universities_logos.map((logo, index) => (
                        <div className="swiper-slide logo-position1" key={index}>
                          <a href={stripHtml(logo.caption)} target="_blank" rel="noopener noreferrer">
                            <img src={logo.source_url} alt={logo.title} />
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    )
  }
}
export default PartnerUniversities;
