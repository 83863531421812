import React, { Component } from "react"
import PopupForm from "../components/popupForm";

import 'react-tabs/style/react-tabs.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import check from '../images/check-icon.svg'
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from "../components/layout-partnership";

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class PartnerFeatures extends Component {

  constructor(props) {
    super(props);
    this.state = {
      time: 90000000
    }
  }

  componentDidMount() {
    var swiper = new Swiper('.universities-swiper', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          slidesPerColumn: 1,
          spaceBetween: 20,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2,
          spaceBetween: 10,
          observer: true,
          observeParents: true,
        }
      }
    });
    var swiper = new Swiper('.universities-swiper', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          slidesPerColumn: 1,
          spaceBetween: 20,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2,
          spaceBetween: 10,
          observer: true,
          observeParents: true,
        }
      }
    });
    var swiper = new Swiper('.universities-swiper', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          slidesPerColumn: 1,
          spaceBetween: 20,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2,
          spaceBetween: 10,
          observer: true,
          observeParents: true,
        }
      }
    });

    var swiper = new Swiper('.blogs-swiper', {
      slidesPerView: 2,
      spaceBetween: 5,
      autoplay: true,
      // direction: 'vertical',
      speed: 300,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        }
      }
    });


  }
  readmoreFucnction = () => {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  readmoreFucnction2 = () => {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  readmoreFucnction3() {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }
 
  handlePopupWindow = () => {
    this.setState({ time: Math.random() })
  }

  render() {
    const { featuredContent,  programModel, worldgradOffers, worldgradSupport} = this.props;

    function  stripHtml(html) {
      // Use a regular expression to remove HTML tags
      return html.replace(/<[^>]*>/g, '');
    }
    return (
      <Layout>
        <PopupForm time={this.state.time} />
        <div class="universitie-partner-sec">
          <div class="container">
            <div class="row">
              <div class="col-12" id="str-crtr-intr">
                <div class="countrie-city-detail">
                  <div class="row">
                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                      <div class="image-holder">
                        <ScrollAnimation animateIn='fadeInLeft'
                          style={{ 'animation-duration': '1s' }}>                        
                          <img 
                            src={featuredContent.featured_content_image.source_url}
                            alt={featuredContent.featured_content_title}
                            class="img-fluid"
                          />
                        </ScrollAnimation>     
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="text-box partnership-features-mid-section">
                        <ScrollAnimation animateIn='fadeInRight'
                          style={{ 'animation-duration': '1s' }}
                        >               
                          <p id='partnership-features-mid-section-heading'>
                            {featuredContent.featured_content_title}
                          </p>
                          <ul>
        {featuredContent.featured_content.map((item, index) => (
          <li key={index}>
            <span><img src={check} alt="✓" /></span>
            {item.read_more_required.length > 0 ? (
              <div>
                <ReactReadMoreReadLess
                  charLimit={30}
                  readMoreText={"Read More ▼"}
                  readLessText={"Read Less ▲"}
                >
                  {stripHtml(item.content)}
                </ReactReadMoreReadLess>
              </div>
            ) : (
              <>
              {stripHtml(item.content)}
            </>
            )}
          </li>
        ))}
      </ul>
                        </ScrollAnimation>        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-5">
          <div className="col-12 partner-program-model-col  text-center">
            <p className="partner-program-model"> {programModel.program_model_title} </p>
            <div className="row mt-5 partner-program-model-row">

              <div className="col-lg-4 p-0">
                <img className="img-fluid partner-vertical-line" src="https://res.cloudinary.com/worldgrad/image/upload/v1624427594/worldgrad/partnership-page/vertical_pbafaw.png" alt="|" />
                <img className="img-fluid" src={programModel.program_model_icon_1.source_url} alt="Icon" />
                <p className="partner-program-model-heading"> {programModel.program_model_title_1} </p>
                <img className="img-fluid partner-horizontal-line" src="https://assets.theworldgrad.com//gatsby-assets/xtra/horizontal-line_edd8xy.png" alt="--" />
                <svg stroke="#D2D4FA" fill="#D2D4FA" stroke-width="0" viewBox="0 0 16 16" class="react-icons-arrow" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>
 <p className="partner-program-model-text" >{stripHtml(programModel.program_model_content_1)} </p>
              </div>
              <div className="col-lg-4 p-0">
                <img className="img-fluid partner-vertical-line" src="https://res.cloudinary.com/worldgrad/image/upload/v1624427594/worldgrad/partnership-page/vertical_pbafaw.png" alt="|" />
                <img className="img-fluid" src={programModel.program_model_icon_2.source_url} alt="Icon" />
                <p className="partner-program-model-heading"> {programModel.program_model_title_2} </p>
                <img className="img-fluid partner-horizontal-line" src="https://assets.theworldgrad.com//gatsby-assets/xtra/horizontal-line_edd8xy.png" alt="--" />
               
                <svg stroke="#D2D4FA" fill="#D2D4FA" stroke-width="0" viewBox="0 0 16 16" class="react-icons-arrow" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>

                <p className="partner-program-model-text" >{stripHtml(programModel.program_model_content_2)} </p>
              </div>
              <div className="col-lg-4 p-0">
                <img className="img-fluid partner-vertical-line" src="https://res.cloudinary.com/worldgrad/image/upload/v1624427594/worldgrad/partnership-page/vertical_pbafaw.png" alt="|" />
                <img className="img-fluid" src={programModel.program_model_icon_3.source_url} alt="Icon" />
                <p className="partner-program-model-heading"> {programModel.program_model_title_3} </p>
                <img className="img-fluid partner-horizontal-line" src="https://assets.theworldgrad.com//gatsby-assets/xtra/horizontal-line_edd8xy.png" alt="--" />
                <svg stroke="#D2D4FA" fill="#D2D4FA" stroke-width="0" viewBox="0 0 16 16" class="react-icons-arrow" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>
  <p className="partner-program-model-text" >{stripHtml(programModel.program_model_content_3)} </p>
              </div>
            </div>
          </div>
        </div>

        <div class="universitie-partner-sec">
          <div class="container">
            <div class="row">
              <div class="col-12" id="str-crtr-intr">
                <div class="countrie-city-detail">
                  <div class="row">
                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                      <div class="image-holder">
                        <ScrollAnimation animateIn='fadeInLeft'
                          style={{ 'animation-duration': '1s' }}>
                          <img
                            src="https://assets.theworldgrad.com//gatsby-assets/xtra/image_1_-min_mlyo6t-H.jpg"
                            alt="Application Form"
                            class="img-fluid"
                          />
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="text-box">
                        <ScrollAnimation animateIn='fadeInRight'
                          style={{ 'animation-duration': '1s' }}
                        >
                          <p id='partnership-features-mid-section-heading'>
                          {worldgradOffers.worldgrad_offers_title}
                          </p>
                          <p className="partner-features-card-sub-heading">
                          {worldgradOffers.worldgrad_offers_subtitle}
                          </p>
                          
                          <ul>
  {worldgradOffers.worldgrad_offers_content.map((offer, index) => (
    <React.Fragment key={index}>
      <li style={{ color: "#000", marginBottom: "0", fontWeight: "500" }}>
        <span><img src={check} alt="✓" /></span> {offer.offers_title}
      </li>
      <li style={{ fontSize: '16px', marginLeft: '30px' }}>
        {stripHtml(offer.offers_content)}
      </li>
    </React.Fragment>
  ))}
</ul>
                          <a class="btn-default" href={worldgradOffers.worldgrad_offers_button_link} >{worldgradOffers.worldgrad_offers_button_title}</a>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="universitie-partner-sec  universitie-partner-sec-inverted mt-5">
          <div class="container">
            <div class="row">
              <div class="col-12" id="str-crtr-intr">
                <div class="countrie-city-detail">
                  <div class="row">
                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                      <div class="image-holder">
                        <ScrollAnimation animateIn='fadeInLeft'
                          style={{ 'animation-duration': '1s' }}>
                          <img
                            src={worldgradSupport.worldgrad_support_image.source_url}
                            alt="Support"
                            class="img-fluid"
                          />
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="text-box p-0 mt-0 mb-5">
                        <ScrollAnimation animateIn='fadeInRight'
                          style={{ 'animation-duration': '1s' }}
                        >
                          <p id='partnership-features-mid-section-heading'>
                          {worldgradSupport.worldgrad_support_title}
                          </p>
                          <ul>
  {worldgradSupport.worldgrad_support_detail.map((detail, index) => (
    <li key={index}>
      <span><img src={check} alt="✓" /></span>
      {stripHtml(detail.worldgrad_support_content)}
    </li>
  ))}
</ul>
                          <a 
                            class="btn-default" 
                            href="mailto:partnerships@theworldgrad.com"
                            // onClick={() => this.handlePopupWindow()}
                          >
                            {worldgradSupport.worldgrad_support_button}
                            
                          </a>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default PartnerFeatures;

